﻿// ----------------------------------------------------------------------------------------------------
// 1. Include functions first (so you can manipulate colors, SVGs, calc, etc)
@import "../../node_modules/bootstrap/scss/functions";

// ----------------------------------------------------------------------------------------------------
// 2. Include any default variable overrides here
// These variables should be set before we load the standard bootstrap variables
// Bootstrap will keep what we set here, and only set variables we have not defined yet
// Unocmment the lines you want to change, or get more from the ../../node_modules/bootstrap/scss/variables.scss

// 2.1 Set primary and secondary color - used all over the theme
$primary:         #3A185D;
$secondary:       #B29C75;
$secondary-light: #CBC7C0;

// 2.2 set Body colors
$body-bg:       #E5E5E5;
$body-color:    #3A185D;

// Greys
$gray-100:      #f8f9fa;
$gray-200:      #F8F8F4;
$gray-300:      #E8E1D6;
$gray-400:      #D6CBC4;
$gray-500:      #adb5bd;
$gray-600:      #6c757d;
$gray-700:      #495057;
$gray-800:      #343a40;
$gray-900:      #212529;

// Gutter width:
$grid-gutter-width:           30px;

// Container padding
// $container-padding-x: $grid-gutter-width * 1.5;

$link-color:                              $primary;
$link-decoration:                         none;
$link-shade-percentage:                   20%;
$link-hover-color:                        shift-color($link-color, $link-shade-percentage);
$link-hover-decoration:                   none;


// ----------------------------------------------------------------------------------------------------
// Special CSS for specific pages
// The body tag always has things like page-xx to say what page it's on. Use these to target special CSS.
// Classes you can use are:
// - page-##
// - page-is-home (this class is only added on home)
// - root-page-## the top level page which is activated, to allow different colors across an entire section
// - page-parent-## the parent page of the current page - If available
// - lang-en / lang-de etc. 

// Set the width of the logo specifically for the home page only
// .page-is-home {
//   header {
//     .logo {
//       width: 250px;
//     }
//   }
// }


// Fonts
$font-family-sans-serif: 'Space Grotesk', -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, "Helvetica Neue", Arial, "Noto Sans",sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
$font-family-base: $font-family-sans-serif;

// Links
// Style anchor elements.
// $link-color: $primary;
// $link-decoration: none;
// $link-hover-color: darken($link-color, 15%);
// $link-hover-decoration: none;

// Fonts
// Font, line-height, and color for body text, headings, and more.
$font-size-base: 1rem; // Assumes the browser default, typically `16px`
// $font-size-lg: ($font-size-base * 1.25);
// $font-size-sm: ($font-size-base * .875);

// $font-weight-light: 300;
// $font-weight-normal: 400;
// $font-weight-bold: 700;

// $font-weight-base: $font-weight-normal;
// $line-height-base: 1.42857;

$h1-font-size: $font-size-base * 3.0;
$h2-font-size: $font-size-base * 2.4;
$h3-font-size: $font-size-base * 1.444;
$h4-font-size: $font-size-base * 1.2;
$h5-font-size: $font-size-base;
$h6-font-size: $font-size-base;

// $headings-font-family: inherit;
$headings-font-weight: 700;
$headings-line-height: 1.2;
$headings-color: $body-color;

// display-headings
$display-font-sizes: (
  1: $font-size-base * 3.333,
  2: $font-size-base * 2.667,
  3: $font-size-base * 1.444,
  4: $font-size-base * 1.2
) ;

$display-font-weight: 300 !default;
$display-line-height: $headings-line-height !default;

// $lead-font-size: ($font-size-base * 1.25);

// ----------------------------------------------------------------------------------------------------
// 3. Include remainder of required Bootstrap stylesheets
@import "../../node_modules/bootstrap/scss/variables";

// 4. Include any default map overrides here
// Add custom colors depending on your style
$custom-colors: (
  "dark": $gray-600,
  "secondary-light": $secondary-light,
  "secondary": $secondary,
  "primary": $primary,
);

// Merge the maps
$theme-colors: map-merge($theme-colors, $custom-colors);

// Optimized and extended spacing for the use of paddings and margins like: px-5 or mb-2
$spacer:                      20px;
$custom-spacers: (
  0: 0,
  1: ($spacer / 2), // 10px
  2: ($spacer * 1), // 20px
  3: ($spacer * 1.5), // 30px
  4: ($spacer * 2), // 40px
  5: ($spacer * 3), // 60px
  6: ($spacer * 4), // 80px
  7: ($spacer * 5), // 100px
  8: ($spacer * 6) // 120px
);

// Merge the maps
$spacers: map-merge(
  $spacers,
  $custom-spacers
 
);

// ----------------------------------------------------------------------------------------------------
// 5. Include remainder of required parts
@import "../../node_modules/bootstrap/scss/maps";
@import "../../node_modules/bootstrap/scss/mixins";
@import "../../node_modules/bootstrap/scss/utilities";

