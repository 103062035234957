.app-news5 {
  &.app-news5-detail {
    // Content Styles
    .app-news5-content {

      .app-news5-date, .app-news5-categories {
        font-size: $font-size-base * 0.8;
        font-weight: bold;
        text-transform: uppercase;
      }
      .app-news5-date {
        &.app-news5-date-border {
          border-right: 1px solid $body-color;
          padding-right: 4px;
          margin-right: 4px;
        }
      }
      .app-news5-categories {
        a {
          color: $link-color;
          letter-spacing: 1px;
          margin-right: 8px;
        }
      }

    }
    .app-news5-detail-teaser {
      font-weight: 700;
    }
  }
}
